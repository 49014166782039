import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import styled from "styled-components";
import HTTPErrorContent from "components/ErrorPage/HTTPErrorContent";
import MaintenanceModeContent from "components/ErrorPage/MaintenanceModeContent";
import dogWithPinkBalloon from "public/dogWithPinkBalloon.mp4";
import catTyping from "public/catTyping.mp4";
import { STATUS_CODE, PRIMARY_TEXT, SECONDARY_TEXT, LINK_TEXT, DOG } from "components/ErrorPage/constants";

// this file doens't use themes because themes aren't availible in _app.js
// where the 404 page is called.
const ErrorPageContainer = styled.div`
  position: fixed;
  background-color: #fafffb;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.5fr 1fr;
  min-height: calc(100vh - 140px);
  height: 100%;
  width: 100%;
  @media (min-width: 992px) {
    grid-template-areas: "link-and-text-container video-container";
    grid-template-rows: 1fr;
    grid-template-columns: 0.4fr 1fr;
  }
`;

const LinkAndContentContainer = styled.div`
  display: flex;
  padding: 0 25px;
  @media (min-width: 567px) {
    align-items: center;
    justify-content: center;
  }
`;

const ContentContainer = styled.div`
  padding-top: 38px;
  padding-bottom: 30px;
  padding-left: 12px;
`;

const VideoContainer = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  inset: 0px;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #232a34;
`;

function ErrorPage({ statusCode, primaryText, secondaryText, link, videoText }) {
  if (!link) {
    link = (
      <Link href="/">
        <a>{LINK_TEXT}</a>
      </Link>
    );
  }
  let content;
  const video = videoText === "dogWithPinkBalloon" ? dogWithPinkBalloon : catTyping;

  if (statusCode) {
    content = (
      <HTTPErrorContent
        statusCode={statusCode}
        primaryText={primaryText}
        secondaryText={secondaryText}
        link={link}
      />
    );
  } else {
    content = (
      <MaintenanceModeContent
        primaryText={primaryText}
        secondaryText={secondaryText}
        link={link}
      />
    );
  }

  return (
    <ErrorPageContainer>
      <LinkAndContentContainer>
        <ContentContainer> {content} </ContentContainer>
      </LinkAndContentContainer>
      <VideoContainer>
        <VideoBg
          autoPlay
          loop
          muted
          playsInline
          src={video}
        />
      </VideoContainer>
    </ErrorPageContainer>
  );
}

export default ErrorPage;

ErrorPage.propTypes = {
  statusCode: PropTypes.string,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  link: PropTypes.any,
  videoText: PropTypes.string,
};

ErrorPage.defaultProps = {
  statusCode: STATUS_CODE,
  primaryText: PRIMARY_TEXT,
  secondaryText: SECONDARY_TEXT,
  link: null,
  videoText: DOG,
};
