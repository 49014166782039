import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// this file doens't use themes because themes aren't availible in _app.js
// where the 404 page is called.
const ContentContainer = styled.div`
  padding-top: 29px;
  padding-bottom: 23px;
  padding-left: 9px;
`;

const ResponseCodeText = styled.div`
  width: 54px;
  font-size: 32px;
  line-height: 38px;
  font-weight: 600;
  color: #ff7817;
`;

const PrimaryText = styled.div`
  padding-top: 12px;
  width: 225px;
  line-height: 40px;
  color: #192617;
  font-size: 34px;
  font-weight: 600;
  color: #192617;
  font-family: "HarmoniaSansProCyr";
`;

const SecondaryText = styled.div`
  padding-top: 8px;
  padding-bottom: 17px;
  font-weight: 400;
  width: 274px;
  font-size: 16px;
  color: #192617;
  font-family: "HarmoniaSansProCyr";
`;

const LinkContainer = styled.form`
  width: 218px;
  color: #ff7817;
  font-size: 14px;
  font-weight: 600;
  font-family: "HarmoniaSansProCyr";
`;

function HTTPErrorContent({ statusCode, primaryText, secondaryText, link }) {
  return (
    <ContentContainer>
      <ResponseCodeText>{statusCode}</ResponseCodeText>
      <PrimaryText>{primaryText}</PrimaryText>
      <SecondaryText>{secondaryText}</SecondaryText>
      <LinkContainer>{link}</LinkContainer>
    </ContentContainer>
  );
}

export default HTTPErrorContent;

HTTPErrorContent.propTypes = {
  statusCode: PropTypes.string,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  link: PropTypes.element,
};

HTTPErrorContent.defaultProps = {
  statusCode: null,
  primaryText: null,
  secondaryText: null,
  link: null,
};
