import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { SUPPORT_EMAIL } from "components/ErrorPage/constants";

const ContentContainer = styled.div`
  padding-top: 38px;
  padding-bottom: 30px;
  padding-left: 12px;
`;

const PrimaryText = styled.div`
  width: 161.008px;
  color: #192617;
  font-size: 32px;
  font-weight: 600;
  font-family: "HarmoniaSansProCyr";
`;

const SecondaryText = styled.div`
  padding-top: 8px;
  padding-bottom: 17.008px;
  font-weight: 400;
  width: 274px;
  font-size: 16px;
  color: #192617;
  font-family: "HarmoniaSansProCyr";
`;

const StyledLink = styled.a`
  color: #ff7817;
  font-size: 14px;
  font-weight: 600;
  font-family: "HarmoniaSansProCyr";
  text-decoration: none;
`;

function MaintenanceModeContent({ primaryText, secondaryText, link }) {
  return (
    <ContentContainer>
      <PrimaryText>{primaryText}</PrimaryText>
      <SecondaryText>
        {secondaryText}
        <StyledLink href={SUPPORT_EMAIL}>{link}</StyledLink>
      </SecondaryText>
    </ContentContainer>
  );
}

export default MaintenanceModeContent;

MaintenanceModeContent.propTypes = {
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  link: PropTypes.element,
};

MaintenanceModeContent.defaultProps = {
  primaryText: null,
  secondaryText: null,
  link: null,
};
