import dynamic from "next/dynamic";

const ErrorPage = dynamic(
  () => import("../../components/ErrorPage"),
  {
    ssr: false,
  }
);

export default ErrorPage; 
