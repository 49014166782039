import React, { Suspense } from "react";
import Head from "next/head";
import Link from "next/link";
import ErrorPage from "dynamic/ErrorPage";
import {
  STATUS_CODE,
  PRIMARY_TEXT,
  SECONDARY_TEXT,
  LINK_TEXT,
  DOG,
} from "../components/ErrorPage/constants";

export default function FourOhFour() {
  return (
    <>
      <Head>
        <title>GoodFriend | 404</title>
      </Head>
      <Suspense>
        <ErrorPage
          statusCode={STATUS_CODE}
          primaryText={PRIMARY_TEXT}
          secondaryText={SECONDARY_TEXT}
          link={
            <Link href="/customer">
              <a>{LINK_TEXT}</a>
            </Link>
          }
          videoText={DOG}
        />
      </Suspense>
    </>
  );
}
