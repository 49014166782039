// 404 page
export const STATUS_CODE = "404";
export const PRIMARY_TEXT = "Dog chewed this page";
export const SECONDARY_TEXT = "Looks like this page does not exist, and we are sorry about that";
export const LINK_TEXT = "Go to the main page";

// Maintenance Mode  page
export const MAINTENANCE_PRIMARY_TEXT = "We'll be right back!";
export const MAINTENANCE_SECONDARY_TEXT =
  "We are currently down for maintenance, but we will be back in no time.  If you have any questions, ";
export const MAINTENANCE_LINK_TEXT = "send us an Email";

// email
export const SUPPORT_EMAIL = "mailto:support@goodfriend.com";

// video
export const DOG = "dogWithPinkBalloon";
export const CAT = "catTyping";
